// import i18next from '@theme/translations'

const siteConfig = {
  winery: 'promontory',
  metadata: {
    title: `Promontory`,
    description: ``,
    phone: '707.944.0124',
    wines: 'Promontory and Penultimate',
  },
  loginPath: '/members',
  loginTitle: 'Members',
  signupPath: '/allocation',
  signupTitle: 'Request Allocation',
  countOfInHouseInventoryLocations: 2,
  director: {
    name: 'Joe Rorke',
    nameWithTitle: 'Joe Rorke',
    email: 'jrorke@promontory.wine',
  },
  patronRelations: {
    name: 'Katerina Caban',
    email: 'katerina@promontory.wine',
    hospitalityEmail: 'hospitality@promontory.wine',
    hospitalityPhone: '707.944.0125',
  },
  isFlacActive: true,
  nextVintageWines: '2022 vintage of Promontory and Penultimate',
  lastVintageWines: '2021 Promontory or Penultimate',
  settings: {
    skipLetterDestination: '/vintage-notes/',
    useCustomerLetters: false,
  },
}

if (process.env.NODE_ENV == 'production') {
  siteConfig.ccWorkaroundInventoryLocationId = 'a7ee9bdc-87d9-4f7d-acf2-de13144e11fc' // "promontory-production", Warehouse
} else {
  siteConfig.ccWorkaroundInventoryLocationId = '6bc1db41-0bad-436f-87aa-f6d7dd134e45' // "promontory" instance, Promontory Office
}

export default siteConfig
